export const ItemType = {
    NONE: 'item',
    SHIPPING: 'item-shipping',
    RECEIVING: 'item-receiving',
    PARTS_FOUND: 'item-parts-found',
    COMBINED: 'item-combined',
    IN_HOUSE_REPAIRS: 'in-house-repairs',
    PUT_AWAY: 'item-put-away',
    WAREHOUSE_MOVEMENT: 'warehouse-movement',
    ALL: 'item-all',
} as const;

export default ItemType;
